import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'

if ((ENV.MODE === 'production' || ENV.MODE === 'staging') && ENV.SENTRY_DSN) {
	import('./utils/monitoring.client.tsx').then(({ init }) => init())
}

const callback = () =>
	startTransition(() => {
		hydrateRoot(
			// @ts-expect-error possibly undefined
			document.getElementById('root'),
			<StrictMode>
				<RemixBrowser />
			</StrictMode>,
		)
	})

if (process.env.NODE_ENV === 'development') {
	import('remix-development-tools').then(({ initClient }) => {
		// Add all the dev tools props here into the client
		initClient()
		callback()
	})
} else {
	callback()
}

// if (window.requestIdleCallback) {
//   window.requestIdleCallback(hydrate)
// } else {
//   // Safari doesn't support requestIdleCallback
//   // https://caniuse.com/requestidlecallback
//   window.setTimeout(hydrate, 1)
// }
